import sortBy from 'lodash/sortBy';
import shuffle from 'lodash/shuffle';
import each from 'lodash/each';
import filter from 'lodash/filter';
import IStatistic from '@/entities/modules/learning-programs/IStatistic';
import SectionMaterial from '@/entities/modules/learning-programs/SectionMaterial';
import ToggleSectionMaterial from '@/entities/modules/learning-programs/ToggleSectionMaterial';
import MaterialStatus from '@/entities/common/testing/MaterialStatus';
import CustomSettings from '@/entities/modules/learning-programs/CustomSettings';
import MaterialTestStatistic from '@/entities/modules/learning-programs/MaterialTestStatistic';
import TestQuestion from '@/entities/modules/learning-programs/test/TestQuestion';
import TestQuestionStatistic from '@/entities/modules/learning-programs/test/TestQuestionStatistic';
import TestSection from '@/entities/modules/learning-programs/test/TestSection';
import BaseMaterial from '@/entities/modules/learning-programs/BaseMaterial';
import ImageSource, { createImageSmSource } from '@/entities/common/sources/ImageSource';
import CustomRoute from '@/entities/common/CustomRoute';
import QuestionType from '@/entities/common/testing/QuestionType';

export default class MaterialTest extends BaseMaterial {
  id: number;
  name: string;
  isRating: boolean;
  hasTestTime: boolean;
  timeLimit: number;
  attemptsCount: number;
  turnsCount: number;
  isRandomQuestions: boolean;
  questionsCount: number;
  maxScore: number = 0;
  settings: CustomSettings | any;
  questions: Array<TestQuestion>;
  sections: Array<TestSection>;
  questionsForDetails: Array<TestQuestion>;
  updatedAtTimestamp: number;
  image?: ImageSource;

  statistic: MaterialTestStatistic;

  constructor(statistic: IStatistic, payload: any, learningProgramsSettings?: CustomSettings) {
    super();

    this.statistic = statistic as MaterialTestStatistic;

    this.id = parseInt(payload.id, 10);
    this.name = payload.name;
    this.isRating = parseInt(payload.is_rating, 10) === 1;
    this.hasTestTime = parseInt(payload.has_test_time, 10) === 1;
    this.timeLimit = this.hasTestTime ? parseInt(payload.test_time, 10) : 0;
    this.attemptsCount = parseInt(payload.attempts_count, 10);
    this.turnsCount = this.attemptsCount;
    this.isRandomQuestions = parseInt(payload.is_random_questions, 10) === 1;
    this.questionsCount = parseInt(payload.questions_count, 10);
    this.maxScore = parseInt(payload.max_points, 10);
    this.questions = [];
    this.sections = [];
    this.questionsForDetails = [];
    this.updatedAtTimestamp = payload.updated_at;
    this.image = createImageSmSource(payload.image);

    if (payload.custom_settings) {
      this.settings = new CustomSettings(payload.custom_settings);
    } else {
      this.settings = learningProgramsSettings;
    }
  }

  clearIsChecked() {
    this.statistic.isChecked = false;
  }

  getInfo(): Array<object> {
    const parts = [];

    // если для теста есть ограничения на прохождение по времени
    if (this.hasTestTime) {
      parts.push(['common_minutes_number_text', this.timeLimit, { 0: this.timeLimit }]);
    }

    // если тест еще не начат выводим общее кол-во попыток и кол-во баллов которые можно заработать
    if (!this.statistic.attempt) {
      parts.push(['common_attempt_number_text', this.attemptsCount, { 0: this.attemptsCount }]);

      // если есть баллы и тест рейтинговый, то показываем сколько чувак может заработать баллов
      if (this.maxScore && this.isRating) {
        parts.push(['common_point_number_text', this.maxScore, { 0: this.maxScore }]);
      }
    } else {
      // показываем сколько чувак использовал попыток и сколько осталось
      parts.push([
        'common_d_from_d_attempts_text',
        this.attemptsCount,
        {
          0: this.statistic.attempt,
          1: this.attemptsCount,
        },
      ]);

      // если тип результата приходит в процентах то показываем результат в процентах
      if (this.settings && parseInt(this.settings.resultValue, 10) === 1) {
        parts.push([`${this.statistic.getPercent()} %`]);
      }

      // если тест рейтинговый показываем сколько баллов из скольки чувак заработал
      if (this.maxScore && this.isRating) {
        parts.push([
          'learn_d_from_d_points_earned_text',
          this.maxScore,
          {
            0: this.statistic.getPoints(),
            1: this.maxScore,
          },
        ]);
      }
    }

    return parts;
  }

  /**
   * Тип материала
   */
  getMaterialType(): string {
    return 'test';
  }

  /**
   * Виджет материала для страницы просмотра программы обучения (выводится в секциях)
   * @param sectionMaterial
   */
  getToggleWidget(sectionMaterial: SectionMaterial): ToggleSectionMaterial {
    const status = this.statistic.getMaterialStatus();

    let lokaliseButtonLabel;

    if (status === MaterialStatus.ASSIGNED) {
      lokaliseButtonLabel = 'learn_course_pass_button';
    } else if (
      this.statistic.getPercent() === 100 ||
      this.statistic.attempt === this.attemptsCount
    ) {
      lokaliseButtonLabel = 'tests_details_text';
    } else {
      lokaliseButtonLabel = 'common_pass_one_more_time_button';
    }

    let widgetParts: unknown[] = [];

    const widgetTestHasAttempts = this.statistic.attempt < this.attemptsCount;

    const widgetTestCountOfAttempts = this.attemptsCount - this.statistic.attempt;

    // время выводим только если есть временное ограничение и мы еще ни разу не проходили тест
    if (this.hasTestTime && this.statistic.attempt === 0) {
      widgetParts.push(['common_minutes_number_text', this.timeLimit, { 0: this.timeLimit }]);
    }

    // попытки выводим если пользователь ни разу не проходил тест в формате 'n попыток'
    if (this.statistic.attempt === 0) {
      widgetParts.push([
        'common_attempt_number_text',
        this.attemptsCount,
        { 0: this.attemptsCount },
      ]);
    } else if (widgetTestHasAttempts && this.statistic.getPercent() < 100) {
      // если проходил тест и попфтки остались тогда в формате 'еще n попыток'
      widgetParts.push([
        'learn_d_attempts_more_text',
        widgetTestCountOfAttempts,
        { 0: widgetTestCountOfAttempts },
      ]);
    }

    // отображаем проценты если это приходят такие настройки и это не первая попытка
    if (
      this.settings &&
      this.settings.resultValue === 1 &&
      this.statistic.attempt !== 0 &&
      status !== MaterialStatus.CHECKING
    ) {
      widgetParts.push(`${this.statistic.getPercent()}%`);
    }

    // отображаем баллы если тест рейтинговый и он не на проверке
    if (status !== MaterialStatus.CHECKING) {
      widgetParts = this.addPointsInfo(
        widgetParts,
        this.isRating,
        this.maxScore,
        this.statistic.points,
      );
    }

    return new ToggleSectionMaterial({
      id: this.id,
      type: this.getMaterialType(),
      routerTo: CustomRoute.TO_LEARNING_PROGRAMS_TEST,
      routerTrackTo: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_TEST,
      name: this.name,
      image: this.image,

      lokaliseTagLabel: 'common_test_lowcase_text',
      buttonLabel: null,
      lokaliseButtonLabel,
      isButtonOutlined: status !== MaterialStatus.ASSIGNED,

      numberingTypeThrough: this.numberingTypeThrough,
      numberingTypeSections: this.numberingTypeSections,
      numberingTypeHierarchical: this.numberingTypeHierarchical,

      isRating: this.isRating,
      isRequired: sectionMaterial.isRequired,
      isLocked: sectionMaterial.isLocked,

      inProgress: status === MaterialStatus.IN_PROGRESS,
      isPassed: status === MaterialStatus.PASSED,
      isFailed: status === MaterialStatus.FAILED,
      isChecking: status === MaterialStatus.CHECKING,
      isChecked: this.statistic.isChecked,

      parts: widgetParts,
    });
  }

  // метод выбирает вопросы для теста и записывает их в this.questions
  selectQuestionsForTest(): void {
    const questionsForTest: Array<TestQuestion> = [];

    if (!this.isRandomQuestions) {
      this.sections = sortBy(this.sections, ['order']);
      each(this.sections, (section: TestSection) => {
        questionsForTest.push(...sortBy(this.getSectionQuestions(section.id), ['order']));
      });

      each(questionsForTest, question => question.shuffleOptions());
      this.questions = questionsForTest;

      return;
    }

    each(this.sections, (section: TestSection) => {
      const sectionQuestion = this.getSectionQuestions(section.id);

      if (section.questionsCount === sectionQuestion.length) {
        questionsForTest.push(...sectionQuestion);
      } else {
        let necessaryCount = section.questionsCount;

        const necessaryQuestions: Array<TestQuestion> = [];

        while (necessaryCount !== 0) {
          const randomIndexArr = Math.floor(Math.random() * sectionQuestion.length);
          necessaryQuestions.push(sectionQuestion[randomIndexArr]);
          sectionQuestion.splice(randomIndexArr, 1);
          necessaryCount -= 1;
        }
        questionsForTest.push(...necessaryQuestions);
      }
    });

    each(questionsForTest, question => question.shuffleOptions());
    this.questions = shuffle(questionsForTest);
  }

  // метод формируем массив объектов из статистики для отображения деталей
  getQuestionsForDetails(): Array<TestQuestion> | undefined {
    if (this.statistic.userResults.length === 0) {
      return;
    }

    this.questionsForDetails = [];
    each(this.statistic.userResults, (statistic: TestQuestionStatistic) => {
      const result = filter(this.questions, o => o.id === statistic.id)[0];
      result.notAnswer = statistic.status === 'not_answer';
      result.isAnswered = !result.notAnswer;
      result.openQuestionAnswer = statistic.answer;
      result.attachedFiles = statistic.userAnswerFiles;
      result.comment = statistic.comment;
      result.userScores = statistic.scores;
      result.isIncorrect = statistic.status === 'fail';
      result.isCorrect = statistic.status === 'success';
      result.isComplete = true;

      if (result.type === QuestionType.SELECT_SEQUENCE) {
        result.defaultOptions = JSON.parse(JSON.stringify(result.options));

        // Сортируем порядок ответов исходя из кейса (if not answered, shuffle)
        if (result.isAnswered) {
          const answers = statistic.orderedUserAnswers;

          result.options.sort((a, b) => answers.indexOf(a.answerId) - answers.indexOf(b.answerId));
        } else {
          result.shuffleOptions();
        }

        // помечаем корректные ответы
        result.options.forEach((option, i) => {
          option.isCorrect = result.defaultOptions[i].answerId === option.answerId;
          option.isChecked = true;
          option.setStatuses();
        });
      } else {
        each(result.options, (option: any) => {
          if (statistic.userAnswers[option.answerId]) {
            option.isChecked = true;
            result.userAnswers.push(option.answerId);
          }
          option.setStatuses();
        });
      }

      this.questionsForDetails.push(result);
    });

    return this.questionsForDetails;
  }

  getQuestions(): Array<TestQuestion> {
    return this.questions;
  }

  setQuestions(questions: Array<TestQuestion>): void {
    this.questions = questions;
  }

  setSections(sections: Array<TestSection>): void {
    this.sections = sections;
  }

  getSectionQuestions(sectionId: number): Array<TestQuestion> {
    return filter(this.questions, question => question.sectionId === sectionId);
  }

  getStatistic(): MaterialTestStatistic {
    return this.statistic;
  }

  setStatistic(statistic: MaterialTestStatistic): void {
    this.statistic = statistic;
  }

  getSaving(payload: any): any {
    const userResult: Array<object> = [];
    each(this.questions, (question: TestQuestion) => {
      const result: any = {};
      result.id = question.id;
      result.user_answers = [];
      result.ordered_user_answers = [];

      if (!payload.testStopped) {
        if (question.type === QuestionType.OPEN_QUESTION) {
          result.answer = question.openQuestionAnswer;

          if (question.uploadedFiles.length) {
            question.uploadedFiles.forEach((file: any) =>
              result.user_answers.push({ value: file.token }),
            );
          }
        } else if (question.type === QuestionType.SELECT_SEQUENCE) {
          result.ordered_user_answers = question.selectSequenceAnswer.map(a => a.answerId);
          result.user_answers = result.ordered_user_answers;
        } else {
          each(question.options, (option: any) => {
            if (option.isChecked) {
              result.user_answers.push(option.answerId);
            }
          });
        }
        // если вызван метод для записи статистики, значит тест завершен
        // проставляем статус 'нет ответа' и ставим флаг завершенности вопросов
        // eslint-disable-next-line no-param-reassign
        question.isComplete = true;
        // eslint-disable-next-line no-param-reassign
        question.notAnswer = !question.isIncorrect && !question.isCorrect;

        if (question.isCorrect) {
          // eslint-disable-next-line no-param-reassign
          question.userScores = question.scores;
        }
      } else if (question.type === QuestionType.OPEN_QUESTION) {
        // если тест досрочно остановлен, то пишем пустую стату в открытый вопрос
        result.answer = '';
      }
      userResult.push(result);
    });

    const savingData: any = {
      id: this.id,
      type: this.getMaterialType(),
      start_time: this.statistic.attempt > 0 ? this.statistic.startTime : payload.startTime,
      end_time: Math.round((new Date() as any) / 1000),
      user_results: userResult,
    };

    if (payload.uuid) {
      savingData.attempt_uuid = payload.uuid;
    }

    return savingData;
  }

  getStatus() {
    return this.statistic.getMaterialStatus();
  }
}
