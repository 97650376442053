export default class TestQuestionStatistic {
  id: number;
  answer: string;
  comment: string;
  scores: number;
  status: string;
  userAnswers: any;
  orderedUserAnswers: any;
  userAnswerFiles: any;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.answer = payload.answer;
    this.comment = payload.comment;
    this.scores = parseInt(payload.scores, 10);
    this.status = payload.status;
    this.userAnswers = payload.user_answers;
    this.orderedUserAnswers = payload?.ordered_user_answers;
    this.userAnswerFiles = payload?.user_answer_files;
  }
}
