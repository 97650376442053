import { cloneDeep, each, find, has, includes, isEqual, shuffle } from 'lodash';
import QuestionType from '@/entities/common/testing/QuestionType';
import IAnswerOption from '@/entities/common/testing/answer-oprions/IAnswerOption';
import ImageSource, { createImageSmSource } from '@/entities/common/sources/ImageSource';
import MCQAnswerOption from '@/entities/common/testing/answer-oprions/MCQAnswerOption';
import SelectImageAnswerOption from '@/entities/common/testing/answer-oprions/SelectImageAnswerOption';
import DNDAnswerOption from '@/entities/common/testing/answer-oprions/DNDAnswerOption';
import VideoSource, { createVideoSource } from '@/entities/common/sources/VideoSource';
import { errorLogProvider } from '@/providers/logProvider';
// @ts-ignore
import { decrypt } from '@/providers/js/cryptProvider';
import SelectSequenceAnswerOption from '@/entities/common/testing/answer-oprions/SelectSequenceAnswerOption';

type DndAnswer = {
  id: number;
  correct: string;
};

export default class Question {
  id: number;
  type: QuestionType = QuestionType.NONE;
  title: string;
  image?: ImageSource;
  video?: VideoSource;
  options: IAnswerOption[] = [];
  defaultOptions: IAnswerOption[] = [];
  isRandomizeAnswers?: boolean;
  hasOpenQuestion: boolean = false;
  isMultipleAnswers: boolean = false;
  isRequired: boolean;
  recommendation: string;

  order: number;
  timestamp: number;
  duration: number;

  userAnswers: number[] = [];
  openQuestionAnswer: string = '';
  dndAnswers: DndAnswer[] = [];
  selectSequenceAnswers: SelectSequenceAnswerOption[] = [];

  isAnswered: boolean = false;
  isCorrect: boolean = false;
  isIncorrect: boolean = false;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);

    if (has(payload, 'type')) {
      switch (payload.type) {
        case 'drag&drop':
          this.type = QuestionType.DND;
          break;
        case 'mcq':
          this.type = QuestionType.MCQ;
          break;
        case 'select-image':
          this.type = QuestionType.SELECT_IMAGE;
          break;
        case 'video':
          this.type = QuestionType.VIDEO;
          break;
        case 'open-question':
          this.type = QuestionType.OPEN_QUESTION;
          break;
        case 'select-sequence':
          this.type = QuestionType.SELECT_SEQUENCE;
          break;
        default:
          errorLogProvider(`Type ${payload.type} is currently not supported`);

          throw new Error();
      }
    }

    if (has(payload, 'question')) {
      this.title = payload.question;
    } else if (has(payload, 'name')) {
      this.title = payload.name;
    } else {
      this.title = '';
    }

    if (has(payload, 'image')) {
      this.image = createImageSmSource(payload.image);
    }

    if (has(payload, 'video')) {
      this.video = createVideoSource(payload.video);
    }

    this.hasOpenQuestion =
      !!payload.is_comment_available || this.type === QuestionType.OPEN_QUESTION;
    this.isRequired = !!payload.is_required;
    this.recommendation = payload.recommendation ? payload.recommendation : '';

    this.order = payload.order ? parseInt(payload.order, 10) : 0;
    this.timestamp = payload.timestamp ? parseInt(payload.timestamp, 10) : 0;
    this.duration = payload.duration ? parseInt(payload.duration, 10) : 0;
    this.isRandomizeAnswers = payload.allow_randomize_answers
      ? parseInt(payload.allow_randomize_answers, 10) === 1
      : false;

    let options;

    if (typeof payload.options === 'string') {
      options = JSON.parse(decrypt(payload.options));
    } else {
      options = payload.options;
    }

    switch (this.type) {
      case QuestionType.MCQ:
        each(options, o => this.options.push(new MCQAnswerOption(o)));
        break;

      case QuestionType.SELECT_IMAGE:
        each(options, o => this.options.push(new SelectImageAnswerOption(o)));
        break;

      case QuestionType.DND:
        each(options, o => this.options.push(new DNDAnswerOption(o)));
        break;

      case QuestionType.SELECT_SEQUENCE:
        options.forEach((option: any) => this.options.push(new SelectSequenceAnswerOption(option)));
        break;

      default:
        break;
    }

    if (has(payload, 'multiple_answers')) {
      this.isMultipleAnswers = !!payload.multiple_answers;
    } else if (this.type === QuestionType.MCQ || this.type === QuestionType.SELECT_IMAGE) {
      let countOfCorrectAnswers = 0;

      each(this.options, option => {
        if (option.isCorrect) {
          countOfCorrectAnswers += 1;
        }
      });

      this.isMultipleAnswers = countOfCorrectAnswers > 1;
    }
  }

  setAnswer(answer: number[]) {
    this.userAnswers = answer;

    this.doIsAnsweredTrigger(answer);
  }

  chooseOptions(answer: number[]) {
    this.options.forEach(option => {
      option.isChecked = answer.some(id => id === option.answerId);
    });
  }

  enableRandomizeAnswers() {
    this.isRandomizeAnswers = true;
  }

  setOpenQuestionAnswer(answer: string) {
    this.openQuestionAnswer = answer.trim();

    this.doIsAnsweredTrigger();
  }

  setDndAnswer(answer: DndAnswer[]) {
    this.dndAnswers = answer;

    this.doIsAnsweredTrigger();
  }

  setSelectSequenceAnswer(answer: []) {
    this.selectSequenceAnswers = answer;
    this.doIsAnsweredTrigger();
  }

  clearUserAnswers() {
    each(this.options, option => {
      // eslint-disable-next-line no-param-reassign
      option.isSuccess = false;
      // eslint-disable-next-line no-param-reassign
      option.isFailed = false;
    });

    this.userAnswers = [];
    this.dndAnswers = [];
    this.selectSequenceAnswers = [];
    this.openQuestionAnswer = '';

    this.isCorrect = false;
    this.isIncorrect = false;

    this.doIsAnsweredTrigger();
  }

  doIsAnsweredTrigger(answer: number[] = []) {
    switch (this.type) {
      case QuestionType.MCQ:
      case QuestionType.SELECT_IMAGE:
        this.isAnswered =
          this.userAnswers.length > 0 ||
          (this.options.length === 0 && this.openQuestionAnswer.length > 0);
        this.chooseOptions(answer);
        break;
      case QuestionType.DND:
        this.isAnswered = this.dndAnswers.length > 0;
        break;
      case QuestionType.OPEN_QUESTION:
        this.isAnswered = this.openQuestionAnswer.length > 0;
        break;
      case QuestionType.SELECT_SEQUENCE:
        this.isAnswered = true;
        break;

      default:
        break;
    }
  }

  check() {
    switch (this.type) {
      case QuestionType.MCQ:
      case QuestionType.SELECT_IMAGE:
        this.checkMcq();
        break;
      case QuestionType.OPEN_QUESTION:
        //
        break;
      case QuestionType.DND:
        this.checkDnd();
        break;

      case QuestionType.SELECT_SEQUENCE:
        this.checkSelectSequence();
        break;

      default:
        break;
    }
  }

  isUserAnswerCorrect() {
    switch (this.type) {
      case QuestionType.MCQ:
      case QuestionType.SELECT_IMAGE:
        return this.isMcqCorrect();
      case QuestionType.DND:
        return this.isDndCorrect();
      case QuestionType.OPEN_QUESTION:
        return this.isOpenQuestionCorrect();
      default:
        return false;
    }
  }

  checkMcq() {
    each(this.options, option => {
      const marked = this.userAnswers.includes(option.answerId);

      // eslint-disable-next-line no-param-reassign
      option.isSuccess = !!option.isCorrect;
      // eslint-disable-next-line no-param-reassign
      option.isFailed = marked && !option.isCorrect;
    });

    const isCorrect = this.isMcqCorrect();

    this.isCorrect = isCorrect;
    this.isIncorrect = !isCorrect;
  }

  isMcqCorrect(): boolean {
    if (this.isMultipleAnswers) {
      const corrects: number[] = [];
      each(this.options, option => {
        if (option.isCorrect) {
          corrects.push(option.answerId);
        }
      });

      return isEqual(corrects.sort(), cloneDeep(this.userAnswers).sort());
    }

    const answerId = this.userAnswers.length > 0 ? this.userAnswers[0] : 0;

    const option = find(this.options, optionItem => optionItem.answerId === answerId);

    if (!option) {
      return false;
    }

    return !!option.isCorrect;
  }

  checkDnd() {
    const isCorrect = this.isDndCorrect();

    this.isCorrect = isCorrect;
    this.isIncorrect = !isCorrect;
  }

  checkSelectSequence() {
    const isCorrect = this.isSelectSequenceCorrect();

    this.isCorrect = isCorrect;
    this.isIncorrect = !isCorrect;
  }

  isDndCorrect() {
    let correct = true;

    each(this.dndAnswers, answer => {
      const original = find(this.options, o => o.answerId === answer.id);

      if (original && original instanceof DNDAnswerOption) {
        if (original.answer !== answer.correct) {
          correct = false;
        }
      }
    });

    return correct;
  }
  isSelectSequenceCorrect() {
    this.selectSequenceAnswers.forEach((a, index) => {
      a.isCorrect = a.answerId === this.defaultOptions[index].answerId;
    });

    return this.selectSequenceAnswers.every(a => a.isCorrect) || false;
  }

  isOpenQuestionCorrect() {
    return this.openQuestionAnswer.length > 0;
  }

  getSaving() {
    if (this.type === QuestionType.OPEN_QUESTION) {
      return {
        id: this.id,
        user_answers: [],
        answer: this.openQuestionAnswer || '',
      };
    }

    const userAnswers: any = [];
    each(this.options, option => {
      userAnswers.push({
        id: option.answerId,
        correct: +includes(this.userAnswers, option.answerId),
      });
    });

    return {
      id: this.id,
      user_answers: userAnswers,
      user_comment: this.openQuestionAnswer || '',
    };
  }

  shuffleOptions() {
    if (this.type === QuestionType.SELECT_SEQUENCE && !this.defaultOptions.length) {
      this.defaultOptions = JSON.parse(JSON.stringify(this.options));
    }

    if (this.isRandomizeAnswers && this.options.length > 0) {
      this.options = shuffle(this.options);
    }
  }

  isChecked() {
    return this.isAnswered && (this.isCorrect || this.isIncorrect);
  }
}
