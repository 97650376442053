<template>
  <transition
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
  >
    <dialog
      ref="modal"
      open
      class="app-modal"
      :style="varStyle"
      @click="checkClick"
    >
      <div
        ref="modalWrapper"
        class="app-modal__wrapper"
      >
        <div
          class="app-modal__container"
          :class="containerClasses"
        >
          <div
            v-if="isDesktop"
            class="app-modal__icon-wrapper"
            :class="iconClassList"
          >
            <span
              v-if="isDisplayedClose"
              class="app-modal__icon app-modal__icon--close"
              @click="close"
            >
              <CloseSvg />
            </span>
            <div
              v-if="$slots.favorite"
              class="app-modal__icon app-modal__icon--favorite"
            >
              <slot name="favorite"></slot>
            </div>
          </div>
          <div
            v-if="!isHiddenHeader"
            class="app-modal__header"
          >
            <span
              v-if="isDisplayedBack"
              class="app-modal__icon app-modal__icon--back"
              @click="$emit('back')"
            >
              <ChevronLeftOutline color="surface-high-emphasis" />
            </span>
            <h3
              v-if="title"
              class="app-modal__title"
            >
              {{ title }}
            </h3>
            <span
              v-if="!isDesktop && isDisplayedClose"
              class="app-modal__icon app-modal__icon--close"
              @click="close"
            >
              <CloseSvg />
            </span>
          </div>

          <div
            class="app-modal__content"
            :class="{ 'app-modal__content--scroll': isScrollContent }"
          >
            <slot></slot>
          </div>

          <div
            v-if="$slots.footer"
            class="app-modal__footer"
          >
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </dialog>
  </transition>
</template>

<script>
import ChevronLeftOutline from '@/components/svg/icons/chevron-left-outline.vue';
import CloseSvg from '@/components/svg/icons/close-24px.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'app-modal',

  components: {
    ChevronLeftOutline,
    CloseSvg,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    isDisplayedClose: {
      type: Boolean,
      default: true,
    },

    isDisplayedBack: {
      type: Boolean,
      default: false,
    },

    isFixedCloseIcon: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      type: [Number, String],
      default: 600,
    },

    maxHeight: {
      type: [Number, String],
      default: 556,
    },

    hasIndents: {
      type: Boolean,
      default: true,
    },

    maxContentHeight: {
      type: [Number, String],
      default: 'auto',
    },
  },

  computed: {
    ...mapGetters('helpers', ['isDesktop', 'wh']),

    isScrollContent() {
      return this.maxContentHeight !== 'auto';
    },

    varStyle() {
      return {
        '--max-height': typeof this.maxHeight === 'string' ? this.maxHeight : `${this.maxHeight}px`,
        '--max-content-height':
          typeof this.maxContentHeight === 'number'
            ? `${this.maxContentHeight}px`
            : this.maxContentHeight,
        '--max-width': typeof this.maxWidth === 'string' ? this.maxWidth : `${this.maxWidth}px`,
        '--view-height': `${this.wh}px`,
      };
    },

    iconClassList() {
      return [
        'app-modal__icon-wrapper',
        { 'app-modal__icon-wrapper--fixed': this.isFixedCloseIcon },
        { 'app-modal__icon-wrapper--translate-x2': !this.$slots.favorite },
      ];
    },

    isHiddenHeader() {
      return !this.title && (!this.hasIndents || this.isDesktop);
    },

    containerClasses() {
      return {
        'app-modal__container--without-indents': !this.hasIndents,
        'app-modal__container--hidden-header': this.isHiddenHeader,
      };
    },
  },

  created() {
    document.body.classList.add('disable-scroll');
  },

  beforeDestroy() {
    document.body.classList.remove('disable-scroll');
  },

  methods: {
    checkClick(e) {
      const acceptableElements = [this.$refs.modal, this.$refs.modalWrapper];

      if (acceptableElements.some(element => element === e.target)) {
        this.close();
      }
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
$icon-size-mobile: 24px;
$icon-size-desktop: 32px;
$icon-indent: 8px;

.app-modal {
  &[open] {
    background-color: var(--surfaceEmphasis20);
    border: none;
    box-sizing: border-box;
    display: flex;
    height: var(--view-height);
    left: 0;
    padding: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 120;

    @media (min-width: $screen-md) {
      align-items: center;
      justify-content: center;
      overflow: auto;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;

    @media (min-width: $screen-md) {
      padding: 50px;
    }

    @media (min-width: $screen-lg) {
      height: 100%;
      left: 0;
      min-height: 100vh;
      overflow: auto;
      padding: 80px;
      position: fixed;
      top: 0;
      width: 100%;
    }
  }

  &__container {
    background-color: var(--surfaceWhite);
    display: grid;
    grid-template:
      'header' auto
      'content' 1fr
      'footer' auto / 100%;
    height: var(--view-height);
    padding: 8px 0 $indent-sm;
    width: 100vw;

    @media (min-width: $screen-md) {
      border-radius: 8px;
      height: auto;
      margin: auto;
      max-height: var(--max-height);
      max-width: 600px;
      min-width: min(var(--max-width), 600px);
      padding: $indent-md 0;
    }

    @media (min-width: $screen-md) and (orientation: landscape) {
      max-height: calc(100vh - 100px);
    }

    @media (min-width: $screen-lg) {
      border-radius: 16px;
      max-height: initial;
      max-width: var(--max-width);
      position: relative;
      width: max-content;
    }

    &--without-indents {
      padding: 0;

      & > .app-modal {
        &__header,
        &__content,
        &__footer {
          padding: 0;

          & > .app-modal {
            &__title {
              padding: $indent-sm;

              @media (min-width: $screen-md) {
                padding: $indent-md;
              }
            }

            &__icon {
              height: 60px;
              padding: $indent-sm;

              @media (min-width: $screen-md) {
                padding: $indent-md;
              }

              @media (min-width: $screen-lg) {
                height: $icon-size-desktop;
                padding: 0;
              }
            }
          }
        }
      }
    }

    &--hidden-header {
      grid-template-rows: 0 1fr auto;
    }
  }

  &__header {
    display: grid;
    grid-area: header;
    grid-template: 'back title close' auto / auto 1fr auto;
    padding: 0 $indent-sm 8px;
    width: 100%;

    @media (min-width: $screen-md) {
      padding: 0 $indent-md $indent-md;
    }

    @media (min-width: $screen-lg) {
      grid-template: 'back title' auto / auto 1fr;
    }
  }

  &__title {
    @include assets-typography-headline;
    @include read-more(28, 4);
    grid-area: title;

    @media (min-width: $screen-lg) {
      @include assets-typography-page-title;
      display: block;
    }
  }

  &__icon-wrapper {
    display: flex;
    flex-direction: column;
    grid-area: header;
    justify-self: end;
    position: sticky;
    top: $icon-indent;
    transform: translate(
      calc(#{$icon-size-desktop} + #{$icon-indent}),
      calc(-#{$icon-size-desktop} - #{$icon-indent})
    );

    &--translate-x2 {
      transform: translate(
        calc(#{$icon-size-desktop} + #{$icon-indent}),
        calc(-#{$icon-size-desktop} * 2)
      );
    }

    &--fixed {
      position: absolute;
      top: calc(-#{$icon-size-desktop} - #{$icon-indent});
      transform: none;
      right: calc(-#{$icon-size-desktop} - #{$icon-indent});
    }
  }

  &__icon {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 28px;

    &--back {
      grid-area: back;
      margin-right: 8px;
    }

    &--close {
      grid-area: close;
      margin-left: 8px;
    }

    &--favorite {
      display: none;
      margin-top: $icon-indent;
    }

    @media (min-width: $screen-lg) {
      align-items: flex-start;
      background-color: var(--surfaceWhite);
      border-radius: 50%;
      box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
      height: $icon-size-desktop;
      width: $icon-size-desktop;

      &--back {
        box-shadow: none;
        margin-left: 0;
        position: initial;
      }

      &--close {
        margin-left: 0;
      }

      &--favorite {
        display: block;
      }
    }

    svg {
      height: $icon-size-mobile;
      width: $icon-size-mobile;

      @media (min-width: $screen-lg) {
        height: $icon-size-desktop;
        width: $icon-size-desktop;
      }
    }
  }

  &__content {
    grid-area: content;
    overflow: auto;
    max-height: var(--max-content-height);
    padding: 0 $indent-sm;
    position: relative;

    @media (min-width: $screen-md) {
      padding: 0 $indent-md;
    }

    @media (min-width: $screen-lg) {
      overflow: inherit;

      &--scroll {
        overflow: auto;
      }
    }
  }

  &__footer {
    grid-area: footer;
    padding: $indent-sm $indent-sm 0;
    z-index: 5;

    @media (min-width: $screen-md) {
      padding: $indent-md $indent-md 0;
    }

    @media (min-width: $screen-lg) {
      background-color: var(--surfaceWhite);
      border-radius: 0 0 16px 16px;
      bottom: -80px;
      position: sticky;
    }
  }
}
</style>
