import CorrectName from '@/entities/common/CorrectName';
import CustomRoute from '@/entities/common/CustomRoute';

export default [
  {
    path: '/lm/:moduleId',
    name: CustomRoute.TO_LEARNING_MATERIALS,
    meta: {
      title: 'common_lm_default_section_title',
      moduleCode: CorrectName.LEARNING_MATERIALS,
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-items/index.vue'),
  },

  {
    path: '/lm/:moduleId/learning_program/:id',
    name: CustomRoute.TO_LEARNING_PROGRAMS_MATERIAL,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      eventOnEnter: 'lj_program_open_btn',
      from: {
        'continue-learning': 'dashboard_continue_course_btn',
        'my-results': 'myresults_go_to_lj_btn',
      },
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-programs/show.vue'),
  },
  {
    path: '/lm/:moduleId/learning_track/:trackId/learning_program/:id',
    name: CustomRoute.TO_LEARNING_TRACK_PROGRAMS,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      eventOnEnter: 'lm_trajectory_lj_open_btn',
      from: {
        'continue-learning': 'dashboard_continue_course_btn',
        'my-results': 'myresults_go_to_lj_btn',
      },
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-programs/show.vue'),
  },
  {
    path: '/lm/:moduleId/learning_program/:id/video/:materialId',
    name: CustomRoute.TO_LEARNING_PROGRAMS_VIDEO,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      eventOnEnter: 'lj_video_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-programs/video.vue'),
  },
  {
    path: '/lm/:moduleId/learning_track/:trackId/learning_program/:id/video/:materialId',
    name: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_VIDEO,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      eventOnEnter: 'lm_trajectory_lj_video_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-programs/video.vue'),
  },
  {
    path: '/lm/:moduleId/learning_program/:id/pdf/:materialId',
    name: CustomRoute.TO_LEARNING_PROGRAMS_PDF,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      eventOnEnter: 'lj_pdf_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-programs/pdf.vue'),
  },
  {
    path: '/lm/:moduleId/learning_track/:trackId/learning_program/:id/pdf/:materialId',
    name: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_PDF,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      eventOnEnter: 'lm_trajectory_lj_pdf_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-programs/pdf.vue'),
  },
  {
    path: '/lm/:moduleId/learning_program/:id/document/:materialId',
    name: CustomRoute.TO_LEARNING_PROGRAMS_DOCUMENT,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      eventOnEnter: 'lj_document_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-programs/document.vue'),
  },
  {
    path: '/lm/:moduleId/learning_track/:trackId/learning_program/:id/document/:materialId',
    name: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_DOCUMENT,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      eventOnEnter: 'lm_trajectory_lj_document_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-programs/document.vue'),
  },
  {
    path: '/lm/:moduleId/learning_program/:id/scorm/:materialId',
    name: CustomRoute.TO_LEARNING_PROGRAMS_SCORM,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      eventOnEnter: 'lj_scorm_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-programs/scorm.vue'),
  },
  {
    path: '/lm/:moduleId/learning_track/:trackId/learning_program/:id/scorm/:materialId',
    name: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_SCORM,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      eventOnEnter: 'lm_trajectory_lj_scorm_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-programs/scorm.vue'),
  },
  {
    path: '/lm/:moduleId/learning_program/:id/html/:materialId',
    name: CustomRoute.TO_LEARNING_PROGRAMS_HTML,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      eventOnEnter: 'lj_html_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-programs/html.vue'),
  },
  {
    path: '/lm/:moduleId/learning_track/:trackId/learning_program/:id/html/:materialId',
    name: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_HTML,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      eventOnEnter: 'lm_trajectory_lj_html_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-programs/html.vue'),
  },
  {
    path: '/lm/:moduleId/learning_program/:id/link/:materialId',
    name: CustomRoute.TO_LEARNING_PROGRAMS_LINK,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      showIntercom: false,
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-programs/link.vue'),
  },
  {
    path: '/lm/:moduleId/learning_track/:trackId/learning_program/:id/link/:materialId',
    name: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_LINK,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      showIntercom: false,
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-programs/link.vue'),
  },
  {
    path: '/lm/:moduleId/learning_program/:id/longread/:materialId',
    name: CustomRoute.TO_LEARNING_PROGRAMS_LONGREAD,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      showIntercom: false,
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-programs/longread.vue'),
  },
  {
    path: '/lm/:moduleId/learning_track/:trackId/learning_program/:id/lingread/:materialId',
    name: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_LONGREAD,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      showIntercom: false,
      showBlockedMessages: false,
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-programs/longread.vue'),
  },
  {
    path: '/lm/:moduleId/learning_program/:id/test/:materialId',
    name: CustomRoute.TO_LEARNING_PROGRAMS_TEST,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      moduleName: CorrectName.LEARNING_PROGRAMS,
    },
    component: () =>
      import(
        /* webpackChunkName: "learning-materials" */ '@/views/learning-programs/test/start-page.vue'
      ),
  },
  {
    path: '/lm/:moduleId/learning_track/:trackId/learning_program/:id/test/:materialId',
    name: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_TEST,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      moduleName: CorrectName.LEARNING_PROGRAMS,
    },
    component: () =>
      import(
        /* webpackChunkName: "learning-materials" */ '@/views/learning-programs/test/start-page.vue'
      ),
  },
  {
    path: '/lm/:moduleId/learning_program/:id/test/:materialId/go',
    name: CustomRoute.TO_LEARNING_PROGRAMS_TEST_GO,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      eventOnEnter: 'lj_test_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
      enableSidebar: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "learning-materials" */ '@/views/learning-programs/test/passing.vue'
      ),
  },
  {
    path: '/lm/:moduleId/learning_track/:trackId/learning_program/:id/test/:materialId/go',
    name: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_TEST_GO,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      eventOnEnter: 'lm_trajectory_lj_test_open_btn',
      showIntercom: false,
      showBlockedMessages: false,
      enableSidebar: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "learning-materials" */ '@/views/learning-programs/test/passing.vue'
      ),
  },
  {
    path: '/lm/:moduleId/learning_program/:id/test/:materialId/results',
    name: CustomRoute.TO_LEARNING_PROGRAMS_TEST_RESULTS,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
    },
    component: () =>
      import(
        /* webpackChunkName: "learning-materials" */ '@/views/learning-programs/test/result-page.vue'
      ),
  },
  {
    path: '/lm/:moduleId/learning_track/:trackId/learning_program/:id/test/:materialId/results',
    name: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_TEST_RESULTS,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
    },
    component: () =>
      import(
        /* webpackChunkName: "learning-materials" */ '@/views/learning-programs/test/result-page.vue'
      ),
  },
  {
    path: '/lm/:moduleId/learning_program/:id/test/:materialId/correct',
    name: CustomRoute.TO_LEARNING_PROGRAMS_TEST_MY_ANSWERS,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      eventOnEnter: 'lj_test_details_btn',
      showIntercom: false,
      showBlockedMessages: false,
      enableSidebar: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "learning-materials" */ '@/views/learning-programs/test/show-correct.vue'
      ),
  },
  {
    path: '/lm/:moduleId/learning_track/:trackId/learning_program/:id/test/:materialId/correct',
    name: CustomRoute.TO_LEARNING_TRACK_PROGRAMS_TEST_MY_ANSWERS,
    meta: {
      title: 'common_learning_journeys_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      eventOnEnter: 'lj_test_details_btn',
      showIntercom: false,
      showBlockedMessages: false,
      enableSidebar: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "learning-materials" */ '@/views/learning-programs/test/show-correct.vue'
      ),
  },

  {
    path: '/lm/:moduleId/learning_track/:id',
    name: CustomRoute.TO_LEARNING_TRACK,
    meta: {
      title: 'common_trajectories_default_text',
      moduleCode: CorrectName.LEARNING_MATERIALS,
      showIntercom: false,
      showBlockedMessages: false,
      eventOnEnter: 'lm_trajectory_open_btn',
      from: {
        'continue-learning': 'dashboard_continue_course_btn',
        'my-results': 'myresults_go_to_traject_btn',
      },
    },
    component: () =>
      import(/* webpackChunkName: "learning-materials" */ '@/views/learning-items/show.vue'),
  },
];
