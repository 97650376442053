/* eslint-disable */
enum QuestionType {
  DND,
  MCQ,
  SELECT_IMAGE,
  VIDEO,
  OPEN_QUESTION,
  NONE,
  SELECT_SEQUENCE,
}

export default QuestionType;
