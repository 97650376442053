import { each, filter, shuffle } from 'lodash';
// @ts-ignore
import { decrypt } from '@/providers/js/cryptProvider';
import QuestionType from '@/entities/common/testing/QuestionType';
import { errorLogProvider } from '@/providers/logProvider';
import ImageSource, { createImageSmSource } from '@/entities/common/sources/ImageSource';
import TestOption from '@/entities/modules/learning-programs/test/TestOption';
import { FileType } from '@/types/learning-programs/tests';

export default class TestQuestion {
  id: number;
  title: string;
  name: string;
  image?: ImageSource;
  testId: number;
  sectionId: number;
  options: Array<TestOption>;
  defaultOptions: Array<TestOption>;
  scores: number;
  order: number;
  type: QuestionType = QuestionType.NONE;
  recommendation: string;
  isRandomizeAnswers: boolean;
  isMultipleAnswers: boolean;
  openQuestionAnswer: string;
  selectSequenceAnswer: Array<TestOption>;
  isComplete: boolean;
  isIncorrect: boolean;
  isCorrect: boolean;
  isAnswered: boolean = false;
  notAnswer: boolean;
  comment: string;
  userScores: number;
  hasOpenQuestion: boolean = false;
  userAnswers: number[] = [];
  fileUploadIsAllowed: boolean;
  fileUploadIsRequired: boolean;
  uploadedFiles: FileType[] = [];
  attachedFiles: [] = [];
  ratingType: string;

  constructor(payload: any) {
    this.id = parseInt(payload.id, 10);
    this.title = payload.name;
    this.name = payload.name;
    this.image = createImageSmSource(payload.image);
    this.testId = parseInt(payload.test_id, 10);
    this.sectionId = parseInt(payload.section_id, 10);
    this.scores = parseInt(payload.score, 10);
    this.order = parseInt(payload.order, 10);
    this.ratingType = payload.rating_type;
    this.recommendation = payload.recommendation;
    this.isRandomizeAnswers = parseInt(payload.allow_randomize_answers, 10) === 1;
    this.fileUploadIsAllowed = parseInt(payload.file_upload_is_allowed, 10) === 1;
    this.fileUploadIsRequired = parseInt(payload.file_upload_is_required, 10) === 1;

    if (payload.question_type) {
      switch (payload.question_type) {
        case 'drag&drop':
          this.type = QuestionType.DND;
          break;
        case 'mcq':
          this.type = QuestionType.MCQ;
          break;
        case 'select-image':
          this.type = QuestionType.SELECT_IMAGE;
          break;
        case 'video':
          this.type = QuestionType.VIDEO;
          break;
        case 'open-question':
          this.type = QuestionType.OPEN_QUESTION;
          break;
        case 'select-sequence':
          this.type = QuestionType.SELECT_SEQUENCE;
          break;
        default:
          errorLogProvider(`Type ${payload.question_type} is currently not supported`);

          throw new Error();
      }
    }

    this.hasOpenQuestion = this.type === QuestionType.OPEN_QUESTION;

    this.options = [];
    this.defaultOptions = [];

    let options;

    if (typeof payload.options === 'string') {
      options = JSON.parse(decrypt(payload.options));
    } else {
      options = payload.options;
    }

    switch (this.type) {
      case QuestionType.MCQ:
        options.forEach((option: any) => this.options.push(new TestOption(option, this.type)));
        break;

      case QuestionType.SELECT_SEQUENCE:
        options.forEach((option: any) => this.options.push(new TestOption(option, this.type)));
        break;

      case QuestionType.SELECT_IMAGE:
        options.forEach((option: any) => this.options.push(new TestOption(option, this.type)));
        break;

      default:
        break;
    }

    this.isMultipleAnswers = filter(this.options, o => o.isCorrect).length > 1;
    this.openQuestionAnswer = '';
    this.selectSequenceAnswer = [];
    this.isComplete = false;
    this.isIncorrect = false;
    this.isCorrect = false;
    this.notAnswer = false;
    this.comment = '';
    this.userScores = 0;
  }

  questionIsCorrect(): boolean {
    let questionIsCorrect = true;

    if (this.isSelectSequenceAnswers()) {
      this.selectSequenceAnswer.forEach((a, index) => {
        a.isCorrect = a.answerId === this.defaultOptions[index].answerId;
      });

      return this.selectSequenceAnswer.every(a => a.isCorrect) || false;
    }

    each(this.options, option => {
      const correctAnswerNotSelected = option.isCorrect && !option.isChecked;

      const notCorrectAnswerSelected = !option.isCorrect && option.isChecked;

      if (correctAnswerNotSelected || notCorrectAnswerSelected) {
        questionIsCorrect = false;
      }
    });

    return questionIsCorrect;
  }

  isSelectSequenceAnswers() {
    return this.type === QuestionType.SELECT_SEQUENCE;
  }

  setQuestionStatuses(): void {
    if (this.type !== QuestionType.OPEN_QUESTION) {
      each(this.options, option => {
        option.setStatuses();
      });

      this.isCorrect = this.questionIsCorrect();
      this.isIncorrect = !this.isCorrect;
    }

    this.isComplete = true;
  }

  hasChosenOption(): boolean {
    return filter(this.options, o => o.isChecked).length > 0;
  }

  shuffleOptions() {
    if (this.isSelectSequenceAnswers()) {
      this.defaultOptions = JSON.parse(JSON.stringify(this.options));
    }

    if (this.isRandomizeAnswers) {
      this.options = shuffle(this.options);
    }
  }

  setAnswer(answer: number[]) {
    this.userAnswers = answer;

    this.doIsAnsweredTrigger();
  }

  setOpenQuestionAnswer(answer: string) {
    this.openQuestionAnswer = answer;

    this.doIsAnsweredTrigger();
  }

  setSelectSequenceAnswer(answer: Array<TestOption>) {
    this.selectSequenceAnswer = answer;

    this.doIsAnsweredTrigger();
  }

  doIsAnsweredTrigger() {
    switch (this.type) {
      case QuestionType.MCQ:
      case QuestionType.SELECT_IMAGE:
        this.isAnswered =
          this.userAnswers.length > 0 ||
          (this.options.length === 0 && this.openQuestionAnswer.length > 0);
        break;
      case QuestionType.OPEN_QUESTION:
        this.isAnswered = this.fileUploadIsRequired
          ? this.uploadedFiles.length > 0 && this.openQuestionAnswer.length > 0
          : this.openQuestionAnswer.length > 0;
        break;
      case QuestionType.SELECT_SEQUENCE:
        this.isAnswered = true;

        break;
      default:
    }
  }

  chooseOptions(answer: Array<number>) {
    this.options.forEach(option => {
      option.isChecked = answer.some(id => id === option.answerId);
    });
  }

  setUploadedFile(file: FileType) {
    this.uploadedFiles.push(file);

    this.doIsAnsweredTrigger();
  }

  deleteUploadedFile(id: number) {
    const file = this.uploadedFiles.find((o: FileType) => o.id === id);

    if (file) {
      const element = this.uploadedFiles.indexOf(file);

      this.uploadedFiles.splice(element, 1);
    }
  }
}
